import { ArrowForward } from "@mui/icons-material";
import { Button, Chip, Paper, Stack, Typography } from "@mui/material";
import { useLanguage } from "hooks";
import { useProjectName } from "hooks/useProjectName";
import { Link } from "react-router-dom";
import translations from "translations";
import ProjectOptionsMenu from "./ProjectOptionsMenu";

const CHIP_WIDTH = 120;
const CHIP_HEIGHT = 30;

type Props = {
  color: string;
  critical: number | string;
  issue: number | string;
  shouldBeFixed: number | string;
  goodToKnow: number | string;
  textColor: string;
  link?: string;
  noColors?: boolean;
  severityMap: {
    "1": string;
    "2": string;
    "3": string;
    "4": string;
  };
  projectID: number | null;
};

interface ISeverityChip {
  severity: number;
  count: number | string;
  noColors: boolean;
  link?: string;
  textColor: string;
  severityMap: {
    "1": string;
    "2": string;
    "3": string;
    "4": string;
  };
}

function SeverityChip({
  severity,
  count,
  noColors,
  link,
  textColor,
  severityMap,
}: ISeverityChip) {
  if (link) {
    return (
      <Link
        to={`${link}?severity=${severity}&new=true`}
        style={{ cursor: "pointer", textDecoration: "none", color: "inherit" }}
      >
        <Chip
          label={count}
          sx={{
            backgroundColor: noColors
              ? undefined
              : severity < 3
                ? "#DE0000"
                : "#FF5634",
            color: textColor,
            fontWeight: "bold",
            fontSize: "small",
            width: CHIP_WIDTH,
            height: CHIP_HEIGHT,
            borderRadius: 20,
            cursor: "pointer",
          }}
        />
      </Link>
    );
  }
  return (
    <Typography
      sx={{
        width: CHIP_WIDTH,
        textAlign: "center",
        color: textColor,
        fontWeight: "bold",
        textOverflow: "ellipsis",
        maxWidth: CHIP_WIDTH,
        whiteSpace: "nowrap",
        overflow: "hidden",
      }}
    >
      {/* @ts-ignore */}
      {severityMap[severity]}
    </Typography>
  );
}

export default function SeverityCountRow({
  color, // @ts-ignore
  link = null,
  critical,
  issue,
  textColor,
  shouldBeFixed,
  goodToKnow,
  noColors,
  severityMap,
  projectID,
}: Props) {
  const { language } = useLanguage();

  let title = "Placeholder";
  // @ts-ignore
  const { fullname } = useProjectName(projectID);
  if (projectID) {
    title = fullname;
  } else {
    title = "";
  }

  return (
    <Paper
      sx={{
        flex: 1,
        px: 2,
        py: 1,
        position: "relative",
        backgroundColor: color,
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        spacing={2}
      >
        <Stack direction="row" alignItems="center">
          {projectID && <ProjectOptionsMenu projectID={projectID} />}
          <Typography
            sx={{
              color: textColor,
              fontWeight: noColors ? "bold" : "normal",
            }}
          >
            {title}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          justifyContent={"flex-end"}
          alignItems="center"
          spacing={1}
        >
          <SeverityChip
            severity={1}
            count={critical}
            // @ts-ignore
            noColors={noColors}
            link={link}
            severityMap={severityMap}
            textColor={textColor}
          />

          <Stack display={{ xs: "none", sm: "block" }}>
            <SeverityChip
              severity={2}
              count={issue}
              // @ts-ignore
              noColors={noColors}
              link={link}
              severityMap={severityMap}
              textColor={textColor}
            />
          </Stack>
          <Stack display={{ xs: "none", md: "block" }}>
            <SeverityChip
              severity={3}
              count={shouldBeFixed}
              // @ts-ignore
              noColors={noColors}
              link={link}
              severityMap={severityMap}
              textColor={textColor}
            />
          </Stack>
          <Stack display={{ xs: "none", lg: "block" }}>
            <SeverityChip
              severity={4}
              count={goodToKnow}
              // @ts-ignore
              noColors={noColors}
              link={link}
              severityMap={severityMap}
              textColor={textColor}
            />
          </Stack>
          <Stack display={{ xs: "none", xl: "block" }}>
            {!!link && !noColors ? (
              <Link
                to={`${link}?severity=1,2,3,4&new=true`}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <Button
                  variant="outlined"
                  size="small"
                  endIcon={<ArrowForward sx={{ fontSize: 14 }} />}
                  sx={{ width: 120, color: textColor }}
                >
                  {translations.words.ShowAll[language]}
                </Button>
              </Link>
            ) : (
              <div style={{ width: 120 }} />
            )}
          </Stack>
        </Stack>
      </Stack>
    </Paper>
  );
}
