import { Stack, Typography } from "@mui/material";
import { useLanguage } from "hooks";
import translations from "../translations";

export default function NoDomainSelected() {
  const { language } = useLanguage();
  return (
    <Stack direction="column" alignItems="center" justifyContent="center">
      <Typography variant="h4">
        {translations.NoDomainSelected[language]}
      </Typography>
      <Typography>{translations.SelectADomainFromTheList[language]}</Typography>
    </Stack>
  );
}
