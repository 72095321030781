import { ArrowBack } from "@mui/icons-material";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { useLanguage } from "hooks";
import translations from "../translations";

export default function BackButton() {
  const { language } = useLanguage();
  return (
    <Link to=".." style={{ textDecoration: "none", color: "inherit" }}>
      <Button variant="text" startIcon={<ArrowBack />} color="inherit">
        {translations.GoBack[language]}
      </Button>
    </Link>
  );
}
