import {
  Button,
  Collapse,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import translations from "translations";
import { useParams } from "react-router-dom";
import NewDefectsProvider from "providers/NewDefectsProvider";
import OptionsMenu from "./OptionsMenu";
import SeverityCountCard from "./SeverityCountCard";
import SeverityCountProjectTable from "./SeverityCountProjectTable";
import { InfoOutlined } from "@mui/icons-material";
import { useLanguage, useTranslation } from "hooks";
import { useState } from "react";

type Props = {
  demoMode: boolean;
};

export default function NewDefects({ demoMode }: Props) {
  const { language } = useLanguage();
  const showMore = useTranslation("ShowMore");
  const showLess = useTranslation("ShowLess");
  const [showTable, setShowTable] = useState<boolean>(false);
  const params = useParams();
  let projectID = null;
  if (params.mission) {
    projectID = parseInt(params.mission);
  }

  const severityMap: {
    "1": string;
    "2": string;
    "3": string;
    "4": string;
  } = {
    "1": translations.words.Severity1[language],
    "2": translations.words.Severity2[language],
    "3": translations.words.Severity3[language],
    "4": translations.words.Severity4[language],
  };

  return (
    // @ts-ignore
    <NewDefectsProvider projectID={projectID}>
      <Stack px={2} py={0} spacing={1} position="relative">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={2}
          ml={1}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={2}
          >
            <Typography variant="h5" component="h2">
              {translations.Landing.NewDefects[language]}
            </Typography>
            <Tooltip
              arrow
              disableInteractive
              title={
                <Stack spacing={2}>
                  <Typography>
                    {translations.Landing.NewDefects.TooltipOne[language]}
                  </Typography>
                  <Typography>
                    {translations.Landing.NewDefects.TooltipTwo[language]}
                  </Typography>
                </Stack>
              }
            >
              <InfoOutlined />
            </Tooltip>
          </Stack>
          <OptionsMenu />
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent={"flex-start"}
          mb={2}
          spacing={2}
        >
          <SeverityCountCard severity="1" severityMap={severityMap} />
          <Stack display={{ xs: "none", sm: "block" }} flex={1}>
            <SeverityCountCard severity="2" severityMap={severityMap} />
          </Stack>
          <Stack display={{ xs: "none", md: "block" }} flex={1}>
            <SeverityCountCard severity="3" severityMap={severityMap} />
          </Stack>
          <Stack display={{ xs: "none", lg: "block" }} flex={1}>
            <SeverityCountCard severity="4" severityMap={severityMap} />
          </Stack>
        </Stack>
        <Button
          variant="text"
          onClick={() => {
            setShowTable(!showTable);
          }}
        >
          {showTable ? showLess : showMore}
        </Button>
        <Collapse in={showTable}>
          <SeverityCountProjectTable
            severityMap={severityMap}
            demoMode={demoMode}
          />
        </Collapse>
      </Stack>
    </NewDefectsProvider>
  );
}
