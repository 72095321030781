import { useEffect, useState } from "react";
import { EventHint, captureException } from "@sentry/react";
import {
  getDomains,
  getDomain,
  addGroupToDomain,
  removeGroupFromDomain,
} from "./api";
import { AxiosError } from "axios";

function handleError(error: unknown, key: string) {
  // Log to the console
  console.error(error);

  // Capture the error with Sentry
  const context: EventHint = {
    event_id: key,
    originalException: error,
  };
  captureException(error, context);

  // Prepare to present the error for the user
  if (error instanceof AxiosError) {
    return error.response?.data || "An error occurred";
  } else if (error instanceof Error) {
    return error.message;
  } else {
    return "An unknown error occurred";
  }
}

// Extract the type of the data returned by the getDomains function
type DomainType = Awaited<ReturnType<typeof getDomains>>;

export function useDomains() {
  // Set up the states for the hook
  const [domains, setDomains] = useState<DomainType>([]);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  async function updateDomains() {
    try {
      // Fetch the domains
      const domains = await getDomains();
      // Update the state with the fetched domains
      setDomains(domains);
    } catch (error) {
      setErrorMessage(
        handleError(error, "domain_manager.hooks.useDomains.updateDomains")
      );
    } finally {
      // Set the loading state to false
      setLoading(false);
    }
  }

  useEffect(() => {
    // Call the updateDomains function when the component mounts
    updateDomains();
  }, []);

  return {
    domains,
    loading,
    errorMessage,
    updateDomains,
    setErrorMessage,
  };
}

type DetailedDomainType = Awaited<ReturnType<typeof getDomain>>;

/**
 * Custom hook to fetch and manage a single domain.
 *
 * @param {number} domainId The ID of the domain to fetch.
 * @returns {object} An object containing the domain, loading state, error message, and updateDomain function.
 */
export function useDomain(domainId: number | undefined) {
  const [domain, setDomain] = useState<DetailedDomainType | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  async function addGroups(groupIds: number[]) {
    if (!domainId) {
      return;
    }
    const promises = groupIds.map((groupId) =>
      addGroupToDomain(domainId, groupId)
    );
    try {
      await Promise.all(promises);
    } catch (error) {
      setErrorMessage(
        handleError(error, "domain_manager.hooks.useDomain.addGroups")
      );
    } finally {
      updateDomain();
    }
  }

  async function deleteGroups(groupIds: number[]) {
    if (!domainId) {
      return;
    }
    const promises = groupIds.map((groupId) =>
      removeGroupFromDomain(domainId, groupId)
    );
    try {
      await Promise.all(promises);
    } catch (error) {
      setErrorMessage(
        handleError(error, "domain_manager.hooks.useDomain.deleteGroups")
      );
    } finally {
      updateDomain();
    }
  }

  async function updateDomainId(targetDomainId: number) {
    setLoading(true);
    setErrorMessage(null);
    try {
      const data = await getDomain(targetDomainId);
      setDomain(data);
    } catch (error) {
      setErrorMessage(
        handleError(error, "domain_manager.hooks.useDomain.updateDomain")
      );
    } finally {
      // Set the loading state to false
      setLoading(false);
    }
  }

  function updateDomain() {
    // Call the updateDomain function when the component mounts or domainId changes
    if (domainId) {
      updateDomainId(domainId);
    } else {
      setDomain(null);
    }
  }

  useEffect(() => {
    // Call the updateDomain function when the component mounts or domainId changes
    if (domainId) {
      updateDomainId(domainId);
    } else {
      setDomain(null);
    }
  }, [domainId]);

  return {
    domain,
    loading,
    errorMessage,
    updateDomain,
    addGroups,
    deleteGroups,
  };
}
