import z from "zod";

// Domains used when listing all domains
export const DomainSchema = z.object({
  id: z.number(),
  name: z.string(),
  url: z.string(),
});

// Detailed domain object, inheriting from the normal domain
export const DetailedDomainSchema = DomainSchema.extend({
  groups: z.array(
    z.object({
      id: z.number(),
      name: z.string(),
    })
  ),
});
