import { useState, useEffect, ReactNode } from "react";
import { IDefectCount, collectNewDefects } from "./utils";
import { IContextValue, context } from "./context";

interface IProviderProps {
  children: ReactNode;
  projectID?: number;
}

function NewDefectsProvider({ children, projectID }: IProviderProps) {
  // Set up some states to keep track of
  const [projects, setProjects] = useState<IDefectCount[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  // Handle the update data and state logic
  async function updateDefectCount(
    projectID: number | null,
    resetCache?: boolean
  ) {
    // Default to no projects
    // @ts-ignore
    let newProjects = [];
    setLoading(true);

    // Collect the data
    try {
      newProjects = await collectNewDefects(projectID, !!resetCache);
    } catch (error) {
      console.error("Failed to collect new defects", error);
      newProjects = [];
    }

    // Update the state
    // @ts-ignore
    setProjects(newProjects);
    setLoading(false);
  }

  useEffect(() => {
    // @ts-ignore
    updateDefectCount(projectID, false);
  }, [projectID]);

  function update() {
    // @ts-ignore
    updateDefectCount(projectID, true);
  }

  const payload: IContextValue = {
    data: projects,
    update,
    loading, // @ts-ignore
    error: null,
  };

  return <context.Provider value={payload}>{children}</context.Provider>;
}

export default NewDefectsProvider;
