import { ReactNode } from "react";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Link } from "react-router-dom";
import { featureLocation } from "features/DomainManager/constants";
import { useSelector } from "hooks";

interface IProps {
  closeSidebar: () => void;
  children?: ReactNode;
}

export default function FlagProtectedDomainMenuButton({
  children,
  closeSidebar,
}: IProps) {
  const isSuperuser = useSelector((state) => state.user.superuser);
  // Render conditionally on feature flags
  const { domainManager } = useFlags();

  // https://app.launchdarkly.com/projects/default/flags/domain-manager
  if (!domainManager) {
    return null;
  }
  if (!isSuperuser) {
    return null;
  }

  return (
    <Link
      onClick={closeSidebar}
      to={featureLocation}
      style={{ textDecoration: "none", color: "inherit" }}
    >
      {children}
    </Link>
  );
}
