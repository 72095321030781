import { useState } from "react";
import { Alert, Button, Divider, Grid, Stack, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import {
  BackButton,
  NoDomainSelected,
  GroupAccessCheckbox,
  DomainCard,
} from "../components";
import { useDomain } from "../hooks";
import { useAdminGroups } from "hooks/projects/useAdminGroups";
import { Refresh } from "@mui/icons-material";
import translations from "../translations";
import { useLanguage } from "hooks";

export default function DomainPage() {
  const { language } = useLanguage();
  const [disabled, setDisabled] = useState(false);

  const [groupsToDelete, setGroupsToDelete] = useState<number[]>([]);
  const [groupsToAdd, setGroupsToAdd] = useState<number[]>([]);

  const [error, setError] = useState<string | null>(null);

  const { groups } = useAdminGroups();
  const params = useParams<{ domain: string }>();
  // Find the domain that matches the ID in the URL

  const domainId = parseInt(params?.domain || "");
  const {
    domain,
    errorMessage,
    updateDomain,
    deleteGroups,
    addGroups,
    loading,
  } = useDomain(domainId);

  // If we don't have a domain, then we show nothing
  if (!domainId) {
    return <NoDomainSelected />;
  } else if (errorMessage) {
    return <Alert severity="error">{errorMessage}</Alert>;
  }

  // Define functions to handle the state of the checkboxes
  function onDeleteGroup(groupId: number) {
    if (groupsToDelete.includes(groupId)) {
      setGroupsToDelete(groupsToDelete.filter((id) => id !== groupId));
    } else {
      setGroupsToDelete([...groupsToDelete, groupId]);
    }
  }

  function onAddGroup(groupId: number) {
    if (groupsToAdd.includes(groupId)) {
      setGroupsToAdd(groupsToAdd.filter((id) => id !== groupId));
    } else {
      setGroupsToAdd([...groupsToAdd, groupId]);
    }
  }
  function resetState() {
    setGroupsToDelete([]);
    setGroupsToAdd([]);
  }

  async function onSave() {
    if (!domain?.id) {
      return;
    }
    setDisabled(true);
    await Promise.all([addGroups(groupsToAdd), deleteGroups(groupsToDelete)]);
    updateDomain();
    setDisabled(false);
  }

  // Define states for the rendering
  const buttonsDisabled =
    disabled || groupsToDelete.length + groupsToAdd.length === 0;

  return (
    <Stack direction="column">
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        mb={1}
        display={{
          xs: "block",
          sm: "none",
        }}
      >
        <BackButton />
      </Stack>
      {error && (
        <Alert sx={{ mb: 1 }} severity="error" onClose={() => setError(null)}>
          {error}
        </Alert>
      )}
      <DomainCard
        title={domain?.name || ""}
        subtitle={domain?.url || ""}
        disableHover
        loading={loading}
      >
        <Divider sx={{ my: 1 }} />
        <Grid container spacing={2} px={2} py={2}>
          <Grid item xs={6} md={4}>
            <Button
              variant="contained"
              color="info"
              fullWidth
              startIcon={<Refresh />}
              disabled={disabled}
              onClick={updateDomain}
            >
              {translations.Refresh[language]}
            </Button>
          </Grid>
          <Grid item xs={3} md={4}>
            <Button
              variant="contained"
              disabled={buttonsDisabled}
              color="error"
              fullWidth
              onClick={resetState}
            >
              {translations.Cancel[language]}
            </Button>
          </Grid>
          <Grid item xs={3} md={4}>
            <Button
              variant="contained"
              color="success"
              fullWidth
              disabled={buttonsDisabled}
              onClick={onSave}
            >
              {translations.Save[language]}
            </Button>
          </Grid>

          {domain && groups.length ? (
            [...groups]
              .sort((a, b) => (a.name < b.name ? -1 : 1))
              .map((group) => {
                // Extract information about the group
                const groupIds = domain.groups.map((g) => g.id);
                const groupHasAccess = groupIds.includes(group.id);

                // Infer state of the checkbox
                const willDelete = groupsToDelete.includes(group.id);
                const willAdd = groupsToAdd.includes(group.id);
                const checked =
                  (groupHasAccess && !willDelete) ||
                  (!groupHasAccess && willAdd);

                return (
                  <GroupAccessCheckbox
                    key={group.id}
                    label={group.name}
                    disabled={disabled}
                    checked={checked}
                    onChange={() => {
                      if (groupHasAccess) {
                        onDeleteGroup(group.id);
                      } else {
                        onAddGroup(group.id);
                      }
                    }}
                    willDelete={willDelete}
                    willAdd={willAdd}
                  />
                );
              })
          ) : (
            <Grid item xs={12}>
              <Typography>{translations.NoGroupsFound[language]}</Typography>
            </Grid>
          )}
        </Grid>
      </DomainCard>
    </Stack>
  );
}
